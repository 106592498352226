<template>
  <v-content
    id="pages"
    :class="$vuetify.theme.dark ? 'light-blue lighten-5' : 'grey lighten-3'"
  >
    <v-responsive
      :style="styles"
      min-height="100vh"
      class="d-flex align-center"
    >
      <router-view />
    </v-responsive>
  </v-content>
</template>

<script>
  export default {
    name: 'PagesCoreView',
    //       :src="require(`@/assets/${src || 'clint-mckoy.jpg'}`)"

    data: () => ({
      srcs: {
        '/pages/lock': '',
        '/pages/login': '',
        '/pages/register': '',
      },
    }),

    computed: {
      src () {
        return this.srcs[this.$route.path]
      },
      styles () {
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
        const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },
    },
  }
</script>
